export default [{
  title: 'Home',
  route: 'home',
  icon: 'HomeIcon',
  resource: 'all',
  action: 'Manage_Home_Page',
},
{
  title: 'Users',
  icon: 'UsersIcon',
  children: [{
    title: 'Admins',
    route: 'admins',
    resource: 'all',
    action: 'Manage_Admins',
  },
  {
    title: 'Clients',
    route: 'clients',
    resource: 'all',
    action: 'Manage_Users',
  },

  ],
},
{
  title: 'Rooms',
  route: 'rooms',
  icon: 'GridIcon',
  resource: 'all',
  action: 'Manage_Rooms',
},
{
  title: 'Devices',
  route: 'devices',
  icon: 'ServerIcon',
  resource: 'all',
  action: 'Manage_Devices',
},
{
  title: 'Scents',
  route: 'scents',
  icon: 'RadioIcon',
  resource: 'all',
  action: 'Manage_Scents',
},
{
  title: 'Slider',
  route: 'slider',
  icon: 'ImageIcon',
  resource: 'all',
  action: 'Manage_Slider',
},
{
  title: 'Inquiries',
  route: 'inquiries',
  icon: 'HelpCircleIcon',
  resource: 'all',
  action: 'Manage_Inquiries',
},
// {
//   title: 'Walk Through',
//   icon: 'ServerIcon',
//   route: 'walkThrough',
//   resource: 'all',
//   action: 'Manage_WalkThrough',
// },
{
  title: 'Roles & Permissions',
  icon: 'ShieldIcon',
  children: [{
    title: 'Roles',
    route: 'roles',
    resource: 'all',
    action: 'Manage_Roles',
  }],
},

{
  title: 'General notification',
  route: 'general_notifications',
  icon: 'BellIcon',
  resource: 'all',
  action: 'Manage_General_Notifications',
},

{
  title: 'Settings',
  icon: 'SettingsIcon',
  children: [{
    title: 'Languages',
    route: 'languages',
    resource: 'all',
    action: 'Manage_Languages',
  },
  {
    title: 'Website Settings',
    route: 'settings',
    resource: 'all',
    action: 'Manage_Website_Pages',
  },
    // {
    //   title: 'About Us',
    //   route: 'about',
    //   resource: 'all',
    //   action: 'Manage_Website_Pages',
    // },
    // {
    //   title: 'Privacy Policy',
    //   route: 'policy',
    //   resource: 'all',
    //   action: 'Manage_Website_Pages',
    // },
    // {
    //   title: 'Terms & Conditions',
    //   route: 'terms',
    //   resource: 'all',
    //   action: 'Manage_Website_Pages',
    // },
  ],
},

]
